import { Loading, LoadingScreen, NavigationMenu } from '@segunosoftware/equinox';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { useIsFetching } from '@tanstack/react-query';
import { Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import OAuth from './components/OAuth';
import ProtectedRoute from './components/ProtectedRoute';
import SentryWrapper from './components/SentryWrapper';
import SupportChat from './components/SupportChat';
import {
	CreatePopup,
	Dashboard,
	EmailMarketingDashboard,
	EmbeddedPopupsList,
	MaxModal,
	PopupDetails,
	PopupEditor,
	PopupsList,
	Reports,
	ReviewsDashboard,
	SuspendedAccount
} from './utils/lazy-load';

const NAVIGATION_LINKS = [
	{ label: 'Popups', destination: '/popups' },
	{ label: 'Forms', destination: '/forms' },
	{ label: 'Reports', destination: '/reports' },
	{ label: 'Email Marketing', destination: '/email' },
	{ label: 'Product Reviews', destination: '/reviews' }
];

export default function App() {
	const location = useLocation();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<SentryWrapper>
				<Routes>
					<Route path="/oauth/*" element={<OAuth />} />
					<Route path="/*" element={<ProtectedRoute element={<AppEmbed />} />} />
				</Routes>
			</SentryWrapper>
		</AppProvider>
	);
}

function AppEmbed() {
	const isFetching = useIsFetching();
	const calculatedLoading = isFetching > 0;
	return (
		<>
			<NavigationMenu navigationLinks={NAVIGATION_LINKS} />
			<Suspense fallback={<LoadingScreen delay={500} />}>
				<Routes>
					<Route path="popups/*">
						<Route path="new" element={<CreatePopup />} />
						<Route path=":id/editor" element={<PopupEditor />} />
						<Route path=":id" element={<PopupDetails />} />
						<Route path="" element={<PopupsList />} />
					</Route>
					<Route path="forms/*">
						<Route path="new" element={<CreatePopup embedded />} />
						<Route path=":id/editor" element={<PopupEditor />} />
						<Route path=":id" element={<PopupDetails />} />
						<Route path="" element={<EmbeddedPopupsList />} />
					</Route>
					<Route path="reports" element={<Reports />} />
					<Route path="email" element={<EmailMarketingDashboard />} />
					<Route path="reviews" element={<ReviewsDashboard />} />
					<Route path="suspended" element={<SuspendedAccount />} />
					<Route path="max-modal" element={<MaxModal />} />
					<Route path="max-modal-test" element={<div>Max modal test content</div>} />
					<Route path="" element={<Dashboard />} />
				</Routes>
			</Suspense>
			<SupportChat />
			{calculatedLoading && <Loading />}
		</>
	);
}
